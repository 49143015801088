<template>
  <div class="login-wrap">
    <div class="box plain-shadow-on-desktop">
      <p class="title has-text-centered">
        Authentication verification
      </p>
      <div v-if="mfaData">
        <section v-if="!codeTextActive">
          <p class="is-size-5 mb-3 has-text-centered">
            Scan the image below with the two-factor authentication app on your phone. If you can’t use a QR
            <span class="code-text-link" @click="codeTextActive = true">enter this text code</span> instead.
          </p>
          <div class="has-text-centered">
            <img :src='qrCodeUrl'/>
          </div>
        </section>
        <section v-else>
          <p class="is-size-5 mb-3 has-text-centered">
            Enter this text code to instead or <span class="code-text-link" @click="codeTextActive = false">switch to QR</span>
          </p>
          <div class="has-text-centered">
            <p class="is-size-2 is-word-break px-6">{{ this.mfaData.secret }}</p>
          </div>
        </section>
      </div>
      <p class="is-size-5 my-3">Enter the code from the application</p>
      <div v-if="mfaData">
        <p class="is-size-5">
          {{ codeTextActive ? 'After setting up the text code' : 'After scanning the QR code image' }}, the app will display a code that you can enter below.
        </p>
      </div>
      <b-input
        class="my-5"
        type="number"
        placeholder="One-time password i.e. XXXXXX"
        v-model.trim="otp"
        :disabled="isLoading"
        @keyup.enter.native="doVerifyOtp"
        autofocus
      />
      <div class="field is-grouped">
        <button class="button is-fullwidth mr-2" @click="cancelAction">Cancel</button>
        <button class="button is-fullwidth ml-2 is-primary" :disabled="!isValidOtp || isLoading" @click="doVerifyOtp">Verify</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mfaEnrollmentDetails, mfaVerify } from '@api/auth'
import { getInvestorData } from '@api/signup'
import { mapState } from 'vuex'
import QRCode from 'qrcode'

export default {
  data() {
    return {
      mfaData: undefined,
      qrCodeUrl: undefined,
      isLoading: false,
      codeTextActive: false,
      otp: '',
    }
  },
  created() {
    const payload = {
      params: { mfa_token: localStorage.getItem('mfa_token') }
    }
    mfaEnrollmentDetails(payload).then((data) => {
      if (data.success) {
        this.mfaData = data.data.mfa
        if (this.mfaData) {
          QRCode.toDataURL(this.mfaData.provisioningUri).then((url) => {
            this.qrCodeUrl = url
            this.isLoading = false
          })
        } else {
          this.isLoading = false
        }
      }
    })
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel', 'identityIsSet']),
    isValidOtp() {
      return this.otp.length === 6
    },
  },
  methods: {
    cancelAction() {
      localStorage.removeItem('mfa_token')
      this.$router.push('/addyPlus/login')
    },
    doVerifyOtp() {
      if (!this.isValidOtp || this.isLoading) return
      this.isLoading = true
      mfaVerify({ mfa_token: localStorage.getItem('mfa_token'), otp: this.otp }).then((data) => {
        this.isLoading = false
        if (data.success) {
          localStorage.setItem('access_token', data.data.accessToken)
          localStorage.setItem('refresh_token', data.data.refreshToken)
          getInvestorData().then((data) => {
            localStorage.setItem('investor', JSON.stringify(data))
            if (!this.identityIsSet && data) {
              this.mixpanel.identify(data.email)
              this.$store.commit('mixpanel/setIdentity', true)
            }
            localStorage.removeItem('mfa_token')
            this.$router.push('/addyPlus/dashboard')
          })
        }
      }).catch((error) => {
        this.otp = ''
        this.isLoading = false
        this.$buefy.toast.open({
          message: error.response.data.error,
          duration: 3000,
          type: 'is-danger',
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.login-wrap {
  padding: 0 25px;
  padding-bottom: 30px;
  > .box {
    margin-bottom: 29px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
  }
  .code-text-link {
    cursor: pointer;
    color: hsl(217, 71%, 53%);
  }
  .is-word-break {
    word-wrap: break-word;
  }
}
</style>
